.container {
  display: flex;
  align-items: center;
  height: 100%;
  .failedIconWrapper {
    margin: 0 auto;
    .failedIcon {
      font-size: 75px;
    }
  }
}
