@import "../../helpers/fonts.scss";
@import "../../helpers/variables.scss";

.button {
  height: 45px !important;
  padding: 15px 15px !important;
  min-width: 116px !important;
  background-color: $yellow !important;
  color: $black_text_color !important;
  font-family: $system_medium_font !important;
  font-size: 12px !important;
  line-height: 15px !important;
  border-radius: 25px !important;
  text-transform: none !important;
}

.outlinedButton {
  border: 2px solid $yellow !important;
  background-color: #ffffff !important;
}

.disable {
  color: $grey-blue !important;
  background-color: $light_yellow !important;
}

.remove {
  background-color: $red !important;
  color: #ffffff !important;
}

@media (max-width: $break_point_lg) {
  .button {
    font-size: 1.2rem !important;
  }
}
