@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

$threads-font-path: "~plaid-threads/fonts";
@import "~plaid-threads/scss/variables";
@import "~plaid-threads/scss/typography";

.container {
  box-sizing: border-box;
  background-color: $white_background;
  background-image: url("../../assets/images/CompositionRight.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  .cardsWrapper {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .card {
      display: flex;
      flex-direction: column;

      .cardHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px 15px;
        > img {
          height: 36px;
          width: 36px;
          margin-right: 20px;
        }
        > h2 {
          margin: 0;
          font-family: $system_semiBold_font;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
}

@media (max-width: $break_point_720) {
  .container {
    .cardsWrapper {
      flex-direction: column;
      .card {
        .cardHeader {
          margin: 10px 0 15px;
        }
      }
    }
  }
}
