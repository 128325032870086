@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

.successModal {
  position: absolute;
  padding: 10px 10px 23px;
  background-color: #ffffff;
  border: 0.5px solid $cyan;
  border-radius: 5px;
  z-index: 1;
  top: 40%;
  box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 4px 0px;
  width: 300px;
  right: 50%;
  transform: translate(50%, 0);
  .successModalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    .successModalHeader {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      .successModalCloseButton {
        background-color: $cyan;
        height: 15px;
        width: 15px;
        border-radius: 7.5px;
        min-width: 0;
        padding: 0;
        > img {
          height: 7px;
          width: 7px;
        }
      }
    }

    h5 {
      margin: 5px 0px 13px;
      font-size: 12px;
      font-family: $system_medium_font;
      color: $black_text_color;
      text-align: center;
    }
    > img {
      margin: 0 auto;
    }
  }
}
