@font-face {
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
tr,
td,
th,
button,
span,
input {
  font-family: "Montserrat";
}
