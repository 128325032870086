@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

.container {
  display: flex;
  align-items: center;
  height: 100%;

  > img {
    height: 40px;
    width: 40px;
  }
  h1 {
    font-family: $system_semiBold_font;
    font-size: 25px;
    color: $black_text_color;
    margin: 14px auto 10px;
  }
  h3 {
    font-family: $system_regular_font;
    font-size: 18px;
    color: $black_text_color;
  }

  .topBackground {
    position: absolute;
    background-image: url("../../assets/images/CompositionTop.png");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    height: 100%;
    width: 100%;
  }

  .bottomBackground {
    position: absolute;
    background-image: url("../../assets/images/CompositionBottom.png");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    height: 100%;
    width: 100%;
  }
}
