@import "../../helpers/fonts.scss";
@import "../../helpers/variables.scss";

.modal {
  outline: 0;
  outline-offset: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 520px;
  max-width: 100%;
  border: 0.5px solid $cyan;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  .closeButtonLine {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 8px;
    .closeButton {
      display: flex;
      height: 32px;
      width: 32px;
      background-color: $cyan;
      border-radius: 16px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .title {
    font-size: 14px;
    font-family: $system_medium_font;
    color: $black_text_color;
    text-align: center;
  }
  .buttonsWrapper {
    width: 65%;
    margin: 30px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

@media (max-width: $break_point_xs) {
  .modal {
    width: 320px;
    .buttonsWrapper {
      width: 90%;
    }
    .closeButtonLine {
      .closeButton {
        height: 24px;
        width: 24px;
        border-radius: 12px;
      }
    }
  }
}
