@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-medium";
  src: url("../assets/fonts/Montserrat-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat-regular";
  src: url("../assets/fonts/Montserrat-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat-light";
  src: url("../assets/fonts/Montserrat-Light.ttf");
  font-weight: 300;
}

$system_semiBold_font: "Montserrat", "Open Sans", "Helvetica Neue";

$system_regular_font: "Montserrat-regular", "Open Sans", "Helvetica Neue";

$system_medium_font: "Montserrat-medium", "Open Sans", "Helvetica Neue";

$system_light_font: "Montserrat-light", "Open Sans", "Helvetica Neue";
