.wrapper {
  padding: 0;
  border-radius: 4px !important;
}
.root {
  background-color: #5bd77f !important;
  border-radius: 4px !important;
  padding: 0px 16px 0 0px !important;
  align-items: center;
  .icon {
    padding: 10px !important;
    background-color: #37b487;
    border-radius: 4px 0 0 4px !important;
  }
}

.error {
  background-color: #f44336 !important;
  .icon {
    background-color: #ad453d;
  }
}
.warning {
  background-color: #ffb400 !important;
  .icon {
    background-color: #f5a623;
  }
}
