@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

.stripeWrapper {
  display: flex;
  height: 100%;
  align-items: flex-start;
  width: 100%;
}

.cardContainer {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;

  .cardDataHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    > h2 {
      font-family: $system_semiBold_font;
      font-size: 18px;
      color: $black_text_color;
    }
    > img {
      height: 19px;
      width: 19px;
      margin-right: 10px;
    }
  }

  .buttonWrapper {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > button {
      border-color: $turquoise;
      color: $black_text_color;
      font-family: $system_semiBold_font;
      font-size: 1.4rem;
      height: 48px;
    }
    .paymentButtonWrapper {
      justify-content: flex-end;
      width: 150px;
      > div {
        max-width: 150px;
        height: 48px;
      }
    }
  }

  .cardDataWrapper {
    border-top: none;
    width: 100%;
    max-width: 100%;

    .cardElementsWrapper,
    .cardElementWrapper {
      border: 1px solid #e2e8ef;
      border-radius: 0px;
      width: 100%;
      position: relative;
      background-color: #fff;
      height: 45px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      > div {
        display: block;
        width: 100%;
      }
    }
    .cardElementsWrapper {
      display: flex;
      flex-direction: column;
      background-color: transparent;
      border: none;
      padding: 0;
      height: auto;
      > div {
        width: 100%;
        height: 45px;
        background-color: #fff;
        border: 1px solid #e2e8ef;
        margin-bottom: 10px;
        padding: 10px 10px 0;
        display: block;
      }
      > div:last-child {
        margin-bottom: 0;
      }
    }
  }

  .promoCodeWrapper {
    width: 100%;
  }
}

.checkboxWrapper {
  width: 100%;
}
