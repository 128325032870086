@import "../../helpers/fonts.scss";
@import "../../helpers/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  padding: 20px 40px;
  .container {
    width: 100%;
    .table {
      min-width: 250px;
      width: 100%;
      max-width: 100%;
    }
  }
}
