@import "../../helpers/fonts.scss";
@import "../../helpers/variables.scss";

.main {
  display: flex;
  min-height: 530px;
  margin: 0 10px 20px;
  width: 275px;
  border: 0.5px solid $light_cyan;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 4px 0px;
  padding: 0 0 10px 0;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  overflow: visible;
  .header {
    display: flex;
    height: fit-content;
    width: 100%;
    background-color: $cyan;
    border-radius: 15px;
    flex-direction: column;
    padding: 20px 15px 8px;
    .planName {
      color: #ffffff;
      font-family: $system_semiBold_font;
      font-size: 18px;
      margin: 0;
    }
    .headerBottomBox {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-top: 20px;
      .amount {
        color: #ffffff;
        font-family: $system_semiBold_font;
        font-size: 35px;
        line-height: 35px;
        margin: 0;
        align-items: flex-end;
      }
      .headerBottomRightBox {
        display: flex;
        flex-direction: column;
        padding: 0 5px;
        > p {
          margin: 0;
          font-family: $system_regular_font;
          color: #ffffff;
          font-size: 10px;
        }
      }
    }
  }
  .body {
    display: flex;
    height: 100%;
    padding: 15px 15px 65px;
    flex-direction: column;
    .bodyHeader {
      > h5 {
        margin: 0;
        font-size: 12px;
        font-family: $system_semiBold_font;
      }
      > p {
        margin: 0;
        font-size: 10px;
        font-family: $system_medium_font;
      }
    }

    .featuresList {
      padding: 5px 0;
      .feature {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;
        > img {
          height: 16px;
          width: 16px;
          margin-right: 10px;
        }
        > p {
          margin: 0;
          font-size: 12px;
          line-height: 15px;
          font-family: $system_regular_font;
        }
      }
    }
  }
  .buttonWrapper {
    width: 100%;
    padding: 0 15px;
    position: absolute;
    bottom: 25px;
    > button {
      width: 100%;
    }
  }
  .bottomLabel {
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 25px;
    padding: 5px 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > p {
      margin: 0;
      font-size: 10px;
      font-family: $system_medium_font;
      color: $cyan;
      text-align: center;
    }
    > img {
      height: 8px;
      width: 8px;
      margin-right: 5px;
    }
  }
}

.active:hover {
  background-color: $yellow;
  .buttonWrapper {
    > button {
      background-color: $cyan !important;
      color: #ffffff !important;
    }
  }
}

.current {
  background-color: $yellow;
  .buttonWrapper {
    > button {
      background-color: $cyan !important;
      color: #ffffff !important;
    }
  }
}

@media (max-width: $break_point_md) {
  .main {
    .header {
      box-sizing: border-box;
      height: 120px;
      min-height: 120px;
      justify-content: space-between;
      .headerBottomBox {
        margin-top: 0;
      }
    }
  }
  .smallPadding {
    min-height: auto;
    .body {
      padding-bottom: 10px;
    }
  }
  .headerWithAutoHeight {
    .header {
      height: 100px;
      min-height: auto;
    }
  }
}
