@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

.switch {
  padding: 4px;
  border: 1.5px solid $yellow;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  .switchItem {
    width: 87px;
    display: flex;
    justify-content: center;
    padding: 0;
    border-radius: 25px;
    &:hover {
      background-color: $light_yellow;
    }
    > p {
      width: 100%;
      text-align: center;
      padding: 2px 0;
      margin: 0;
      font-size: 10px;
      font-family: $system_regular_font;
      text-transform: none;
    }
  }
  .activeSwitchItem {
    background-color: $yellow;
    &:hover {
      background-color: $yellow;
    }
  }
}
