.root {
  display: flex;
  min-height: 100vh;
}

.appContent {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
}
