@import "../../helpers/fonts.scss";
@import "../../helpers/variables.scss";

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 20px 40px;
  width: 100%;
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    .backButton {
      display: none;
    }
    > h1 {
      font-family: $system_semiBold_font;
      font-size: 25px;
      line-height: 30px;
      margin: 0;
      text-align: center;
    }
    > h2 {
      font-family: $system_regular_font;
      font-size: 14px;
      line-height: 17px;
      margin: 15px 0 23px;
      text-align: center;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    width: 80%;
    .planDetailsWrapper {
      min-width: 305px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
      > div {
        border-radius: 15px;
        box-shadow: none;
        border: 0.7px solid $light_cyan;
        margin-bottom: 0;
        width: 100%;
      }
    }
    .paymentDetailsWrapper {
      min-width: 350px;
      display: flex;
      flex-direction: column;
      border: 0.7px solid $light_cyan;
      border-radius: 15px;
      width: 60%;
      align-items: center;
      margin: 0 10px;
      position: relative;
      .header {
        display: flex;
        flex-direction: row;
        margin: 25px 0;
        justify-content: space-between;
        padding: 0 25px;
        position: relative;
        .headerEmptyBox {
          height: 25px;
          width: 25px;
          align-items: center;
        }
        .headerTitle {
          display: flex;
          flex-direction: row;
          align-items: center;
          > img {
            height: 18px;
            width: 18px;
            margin-right: 10px;
          }
          > h2 {
            margin: 0;
            padding: 0;
            font-family: $system_semiBold_font;
            font-size: 18px;
            color: $black_text_color;
          }
        }
        .menuActive {
          display: flex;
          cursor: pointer;
          height: 25px;
          width: 25px;
          background-position-x: center;
          background-position-y: center;
          background-repeat: no-repeat;
          background-image: url("../../assets/images/Menu.svg");
          align-self: flex-end;
        }
        .menuContainer {
          position: absolute;
          right: 20px;
          top: 0px;
          padding: 10px;
          border: 0.5px solid $cyan;
          border-radius: 5px;
          background-color: white;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          width: 108px;
          z-index: 1;
          .menuCloseButton {
            align-self: flex-end;
            background-color: $cyan;
            height: 14px;
            width: 14px;
            border-radius: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            > img {
              height: 6px;
              width: 6px;
            }
          }
          .menuRow {
            width: 100%;
            font-size: 15px;
            line-height: 17px;
            font-family: $system_regular_font;
            color: $black_text_color;
            cursor: pointer;
          }
          .separator {
            width: 100%;
            height: 1px;
            background-color: $black_text_color;
            margin: 4px 0;
          }
        }
      }
    }
  }
}

@media (max-width: $break_point_md) {
  .main {
    .header {
      .backButton {
        top: 0;
        left: 0;
        display: block;
        position: absolute;
        background-color: $yellow;
        height: 24px !important;
        width: 24px !important;
        padding: 0 !important;
        min-width: 0 !important;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        > img {
          height: 100%;
          width: 100%;
        }
        &:hover {
          border-radius: 16px;
        }
      }
    }
    .content {
      flex-direction: column;
      align-items: center;
      .planDetailsWrapper {
        width: 100%;
        margin-bottom: 20px;
        > div {
          margin: 0;
        }
      }
      .paymentDetailsWrapper {
        width: 100%;
      }
    }
  }
}

@media (max-width: $break_point_xs) {
  .main {
    padding: 20px;
    .header {
      h1 {
        font-size: 14px;
        line-height: 17px;
      }
      h2 {
        font-size: 12px;
        line-height: 14px;
        margin: 15px 0;
      }
    }
    .content {
      width: 100%;
      flex-direction: column-reverse;
      .paymentDetailsWrapper {
        min-width: auto;
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin: 0;
        .header {
          margin: 20px 0;
          .headerTitle {
            h2 {
              font-size: 14px;
            }
            img {
              height: 15px;
              width: 15px;
            }
          }
        }
      }
      .planDetailsWrapper {
        min-width: auto;
        > div {
          border-top: none;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }
}
