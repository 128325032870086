@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

.defaultMethodFormWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  .defaultMethodForm {
    span:last-child {
      color: $cyan;
      font-family: $system_regular_font !important;
      font-size: 12px;
      line-height: 15px;
    }
  }
}
