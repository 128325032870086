@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  flex-direction: column;
  .header {
    display: flex;
    flex-direction: row;
    margin: 25px 0;
    justify-content: space-between;
    padding: 0 25px;
    position: relative;
    width: 100%;
    .headerEmptyBox {
      height: 25px;
      width: 25px;
      align-items: center;
    }
    .headerTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      > img {
        height: 18px;
        width: 18px;
        margin-right: 10px;
      }
      > h2 {
        margin: 0;
        padding: 0;
        font-family: $system_semiBold_font;
        font-size: 18px;
        color: $black_text_color;
      }
    }
    .menuActive {
      display: flex;
      cursor: pointer;
      height: 25px;
      width: 25px;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/Menu.svg");
      align-self: flex-end;
    }
    .menuContainer {
      position: absolute;
      right: 20px;
      top: 0px;
      padding: 10px;
      border: 0.5px solid $cyan;
      border-radius: 5px;
      background-color: white;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      width: 108px;
      z-index: 1;
      .menuCloseButton {
        align-self: flex-end;
        background-color: $cyan;
        height: 14px;
        width: 14px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        > img {
          height: 6px;
          width: 6px;
        }
      }
      .menuRow {
        width: 100%;
        font-size: 15px;
        line-height: 17px;
        font-family: $system_regular_font;
        color: $black_text_color;
        cursor: pointer;
      }
      .separator {
        width: 100%;
        height: 1px;
        background-color: $black_text_color;
        margin: 4px 0;
      }
    }
  }
}

@media (max-width: $break_point_430) {
  .container {
    .cardBody,
    .bankBody {
      width: 240px;
    }
  }
}
