@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

.cardBody {
  margin: 30px 15px 0;
  background-color: white;
  padding: 20px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 4px 0px;
  width: 360px;
  position: relative;
  .cardMain {
    .cardBodyHeader {
      height: 25px;
      width: 100%;
      margin-bottom: 35px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      position: relative;
      .menuContainer {
        position: absolute;
        right: 10px;
        top: 0px;
        padding: 10px;
        border: 0.5px solid $cyan;
        border-radius: 5px;
        background-color: white;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        width: 108px;
        .menuCloseButton {
          align-self: flex-end;
          background-color: $cyan;
          height: 14px;
          width: 14px;
          border-radius: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          > img {
            height: 6px;
            width: 6px;
          }
        }
        .menuRow {
          width: 100%;
          font-size: 15px;
          line-height: 17px;
          font-family: $system_regular_font;
          color: $black_text_color;
          cursor: pointer;
        }
        .separator {
          width: 100%;
          height: 1px;
          background-color: $black_text_color;
          margin: 4px 0;
        }
      }
      .menuDisabled {
        display: flex;
        cursor: pointer;
        height: 25px;
        width: 25px;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-image: url("../../assets/images/Menu_disabled.svg");
        align-self: flex-end;
      }
      .menuHided {
        display: flex;
        cursor: default;
        height: 25px;
        width: 25px;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-image: url("../../assets/images/Menu_disabled.svg");
        align-self: flex-end;
        opacity: 0;
      }
      .menuActive {
        display: flex;
        cursor: pointer;
        height: 25px;
        width: 25px;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-image: url("../../assets/images/Menu.svg");
        align-self: flex-end;
      }
    }
    .cardBodyRow {
      width: 100%;
      margin-bottom: 35px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0 35px;

      .noBankAccountWrapper {
        height: 140px;
      }
      .bankAccount {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        .bankAccountItem {
          flex-direction: column;
          width: 100%;
          max-width: 100%;
          overflow: visible;
        }
      }
    }
    .cardBodyFooter {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0 35px;
      > button {
        width: 100%;
      }
    }
  }
  .arrowLeft {
    position: absolute;
    top: 40%;
    left: 10px;
    width: 20px;
  }
  .arrowRight {
    position: absolute;
    top: 40%;
    right: 10px;
  }
}

.bankAccountData {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 141.5px;
  width: 100%;
  max-width: 100%;
  .row {
    display: flex;
    flex-direction: row;
    margin: 0px 0 7px;
    width: 100%;
    max-width: 100%;
    .label {
      color: $black_text_color;
      font-family: $system_medium_font;
      font-size: 14px;
      margin: 0 9px 0 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
    }
    .value {
      font-family: $system_regular_font;
      font-size: 14px;
      width: auto;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      text-transform: capitalize;
    }
  }
}

.noBankAccount,
.addBankAccount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  > p {
    text-align: center;
    font-family: $system_medium_font;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 30px;
  }
  > img {
    margin: 0 auto;
  }
}
.addBankAccount {
  > p {
    margin-bottom: 40px;
  }
}

@media (max-width: $break_point_720) {
  .cardBody {
    margin: 0 0 15px;
  }
}

@media (max-width: $break_point_xs) {
  .cardBody {
    .cardBodyHeader {
      margin-bottom: 15px;
    }
    .cardBodyRow {
      margin-bottom: 0;
    }
  }
}

@media (max-width: $break_point_xxs) {
  .cardBody {
    width: 300px;
  }
}
