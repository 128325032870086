$break_point_xlg: 1280px;
$break_point_lg: 1024px;
$break_point_md: 900px;
$break_point_760: 760px;
$break_point_720: 720px;
$break_point_680: 680px;
$break_point_620: 620px;
$break_point_sm: 580px;
$break_point_xs: 480px;
$break_point_430: 430px;
$break_point_xxs: 360px;

$turquoise: #31cfd9;
$grey-blue: #2e5266;
$yellow: #ffcf00;
$light_yellow: rgba(255, 207, 0, 0.2);
$blue: #2f6690;
$black_text_color: #000000;
$cyan: #205368;
$light_cyan: rgba(32, 83, 104, 0.4);
$red: #ee0000;
$white_background: #faf9f9;
$grey: #636363;
$green: #34a853;
