@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

.promoCodeContainer,
.promoCodeContainerLarge {
  height: 38px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  position: relative;
  border: 1px solid $cyan;
  border-radius: 5px;
  max-width: 100%;
  .body {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 38px;
    padding: 4px;
    justify-content: space-between;
    width: 100%;
    > div {
      width: 100%;
      height: 100%;
      > input {
        padding: 0 0 0 10px;
        font-family: $system_light_font;
        font-size: 14px;
      }
    }
    > button {
      padding: 0px 40px;
      background-color: $cyan;
      border-radius: 5px;
      color: #ffffff;
      font-family: $system_medium_font;
      font-size: 12px;
      height: 100%;
      border: none;
    }
    .promoCodeButtonDisabled {
      background-color: $light_cyan;
    }
  }
}

.promoCodeMessageContainer,
.promoCodeDefaultLogoContainer {
  position: absolute;
  top: -35px;
  border: 1px solid $cyan;
  border-radius: 5px;
  padding: 3px 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: auto;
  .promoCodeDescriptionWrapper {
    position: relative;
    padding-right: 10px;
    .promoCodeBottomArrow {
      position: absolute;
      border-bottom: 1px solid $cyan;
      border-right: 1px solid $cyan;
      height: 10px;
      width: 10px;
      background-color: #fff;
      z-index: 0;
      bottom: -9px;
      left: 50%;
      transform: rotate(45deg);
    }
    .promoCodeDescriptionText {
      margin: 0;
      width: 100%;
      font-size: 10px;
      font-family: $system_medium_font;
      color: $cyan;
      z-index: 1;
    }
  }
  .cleanIconWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .cleanIcon {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
  }
}

@media (max-width: $break_point_760) {
  .promoCodeContainer {
    .promoCodeMessageContainer {
      top: -47px;
      .promoCodeDescriptionWrapper {
      }
    }
  }
}

@media (max-width: $break_point_sm) {
  .promoCodeContainer {
    min-width: 275px;
    .promoCodeDefaultLogoContainer {
      top: -47px;
      .promoCodeDescriptionWrapper {
      }
    }
  }
}

@media (max-width: $break_point_xs) {
  .promoCodeContainer {
    .body {
      > div {
        > input {
          font-size: 10px;
        }
      }
    }
  }
}

@media (max-width: $break_point_430) {
  .promoCodeContainer {
    .body {
      > button {
        padding: 0 20px;
      }
    }
  }
}

@media (max-width: $break_point_xxs) {
  .promoCodeContainer {
    .body {
      > div {
        > input {
          font-size: 9px;
          padding: 0;
        }
      }
    }
  }
}
