@import "../../helpers/fonts.scss";
@import "../../helpers/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  padding: 0px 15px;
  .container {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    min-height: 100%;
    overflow-y: scroll;
    position: relative;
    .header {
      .headerTitle {
        font-family: $system_semiBold_font;
        font-size: 15px;
        line-height: 18px;
        padding: 22px 15px;
        border-color: $cyan;
        max-width: 200px;
        min-width: auto;
      }
      .headerNameCell {
        width: 15%;
      }
      .headerSumCell {
        width: 25%;
      }
      .headerDateCell {
        width: 35%;
      }
      .headerStatusCell {
        width: 15%;
      }
    }
    .nameCell {
      font-family: $system_medium_font;
      font-size: 13px;
      color: $cyan;
      border-color: $light_cyan;
    }
    .sumCell {
      font-family: $system_semiBold_font;
      font-size: 13px;
      color: $cyan;
      flex-direction: column;
      border-color: $light_cyan;
      > div {
        text-transform: uppercase;
      }
    }
    .dateCell {
      font-family: $system_regular_font;
      font-size: 13px;
      color: $grey;
      border-color: $light_cyan;
    }
    .statusCell {
      height: 100%;
      border-color: $light_cyan;
      > div {
        margin: 0 auto;
        width: 124px;
        height: 34px;
        border-radius: 25px;
        padding: 9px 0;
        text-align: center;
        font-size: 13px;
        line-height: 15px;
      }
      .paid {
        border: 1px solid $green;
        color: $green;
      }
      .unpaid {
        border: 1px solid $yellow;
        color: $yellow;
      }
    }
    .emptyList {
      position: absolute;
      top: 48%;
      flex-direction: column;
      > p {
        font-size: 14px;
        line-height: 17px;
        font-family: $system_medium_font;
        margin-top: 20px;
      }
      > img {
        height: 32px;
        width: 32px;
      }
    }
  }
}
