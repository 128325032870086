@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

.cardScreen {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 340px;
  width: 100%;
  .card {
    width: 100%;
    margin: 0 auto;
    padding: 15px 15px 0px;
    background-color: $cyan;
    border-radius: 10px;
    .cardRow {
      width: 100%;
      margin-bottom: 15px;
      .cardTitle {
        font-size: 14px;
        color: #fff;
        font-family: $system_regular_font !important;
        margin-bottom: 5px;
        line-height: 17.5px;
      }
      .cardRowItemLeft {
        width: 50%;
      }
      .cardRowItemRight {
        width: 35px;
        align-items: flex-end;
      }

      .fieldValue {
        margin: 0;
        font-size: 14px;
        color: white;
      }
    }
    .cardRowImage {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      .masterCardImage {
        width: 44px;
        height: 22px;
      }
      .visaCardImage {
        width: 35px;
        height: 22px;
      }
    }
  }
  .defaultMethodFormWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    .defaultMethodForm {
      span:last-child {
        color: $cyan;
        font-family: $system_regular_font !important;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}
