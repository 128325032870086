@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

.arrow {
  background-color: $yellow;
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
  min-width: 0 !important;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > img {
    height: 100%;
    width: 100%;
  }
  &:hover {
    border-radius: 10px;
  }
}
.arrowDisabled {
  opacity: 0.6;
}
